import React, { useMemo } from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import {
  Dropdown,
  DropdownOptionProps,
} from 'wix-ui-tpa/Dropdown';

import { Theme } from '../../../../../common/context/theme';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { st, classes } from './GroupVisibility.st.css';

import { VisibilityMap } from '../../../../Group/Widget/Privacy/VisibilityMap';

export interface GroupVisibilityDropdownProps extends Theme {
  selectedType: ApiTypes.v1.GroupPrivacyLevel;
  onSelect(option: any): void;
}

export const GroupVisibilityDropdown: React.FC<GroupVisibilityDropdownProps> = (props) => {
  const { onSelect, selectedType, forceBlackAndWhite } = props;
  const { t } = useTranslation();

  const options = useMemo<DropdownOptionProps[]>(() => Object.entries(VisibilityMap).map(([id, privacyUiMap]) => {
    const { title, label, icon } = privacyUiMap;

    const optionDataHookPrefix = 'group-visibility-option-';
    const dataHook = `${optionDataHookPrefix}${id}`;

    return {
      id,
      isSelectable: true,
      value: t(title),
      subtitle: t(label),
      icon: <div className={classes.groupTypeIcon} data-hook={dataHook}>{icon}</div>,
      dataHook: privacyUiMap.dataHook,
    };
  }), [t]);


  const dataHook = 'group-visibility-dropdown';

  return (
    <Dropdown
      upgrade
      className={st(classes.root, { bw: !!forceBlackAndWhite })}
      data-hook={dataHook}
      options={options}
      onChange={onSelect}
      initialSelectedId={selectedType}
    />
  );
};
