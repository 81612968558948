import React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { GroupsProvider } from './GroupsContext';
import { GroupsActionsProvider } from './GroupsActionsContext';
import { CurrentUserProvider } from './CurrentUserContext';

import { classes, st } from '../Widget/App/App.st.css';
import { GroupPageWithStyles } from '../Widget/App/types';
import { isMobileByFormFactor } from '../../../common/utils/utils';
import { WixSiteMembersContext } from '../../../common/context/members/WixSiteMembersContext';
import { GROUPS } from '../../../common/components/GroupList/dataHooks';

type GroupsAppProps = React.PropsWithChildren<GroupPageWithStyles>;
export const GroupsAppProvider: React.FunctionComponent<GroupsAppProps> = (
  props: GroupsAppProps,
) => {
  const {
    actions,
    createGroupPolicy,
    currentUser,
    groupNameQuery,
    groups,
    groupsSortBy,
    host: { formFactor },
    isGroupCreating,
    currentSiteMember,
    promptPublicProfile,
    children,
    navigatingToGroup,
    groupSectionUrl,
    updateProgress,
    groupQuestions,
    isRTL,
    groupUrls,
    metaSiteId,
    dashboardLink,
  } = props;
  const isMobile = isMobileByFormFactor(formFactor);
  const makeProfilePublic = actions && actions.makeProfilePublic;

  const dir = isRTL ? 'rtl' : null;
  return (
    <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
      <GroupsProvider
        value={{
          groups,
          isGroupCreating,
          groupNameQuery,
          groupsSortBy,
          createGroupPolicy,
          promptPublicProfile,
          navigatingToGroup,
          groupSectionUrl,
          updateProgress,
          groupQuestions,
          groupUrls,
          metaSiteId,
          dashboardLink,
        }}
      >
        <GroupsActionsProvider value={actions || ({} as any)}>
          <CurrentUserProvider value={currentUser}>
            <WixSiteMembersContext.Provider
              value={{
                currentSiteMember,
                makeProfilePublic,
              }}
            >
              <div
                data-hook={GROUPS}
                className={st(classes.root, {
                  navigating: !!navigatingToGroup,
                })}
                dir={dir as any}
              >
                {children}
              </div>
            </WixSiteMembersContext.Provider>
          </CurrentUserProvider>
        </GroupsActionsProvider>
      </GroupsProvider>
    </TPAComponentsProvider>
  );
};

GroupsAppProvider.displayName = 'GroupsAppProvider';
