import {
  I$WWrapper,
  IFedOpsLogger,
  ILocation,
  INavigateToPageOptions,
  INavigateToSectionOptions,
  ISiteApis,
  IUser,
  IWebStorage,
  IWidgetController,
  IWixAPI,
  IWixWindowViewMode,
  SiteStructure,
  StructurePage,
} from '@wix/native-components-infra/dist/src/types/types';
import { BasicStorage } from '../storage/BasicStorage';
import { ControllerErrorLogger } from '../loggers/ControllerErrorLogger';
import { Environment } from '../loggers/sentryConfig';
import { FedopsLogger } from '../loggers/FedopsLogger';
import getBaseUrl from '../utils/baseUrl';
import { GROUPS_APP_ID } from '../../config/constants';
import { ControllerParams } from '@wix/yoshi-flow-editor';

interface AppStructurePage extends StructurePage {
  applicationId?: string;
}

export abstract class BaseWidgetController<IState>
  implements IWidgetController
{
  private currentUser: IUser;
  protected state: Partial<IState> = {};
  private memoryStorage!: IWebStorage;
  protected errorLogger: ControllerErrorLogger;
  private fedopsLogger!: IFedOpsLogger;
  private readonly appDefId: string;

  protected readonly controllerConfig!: ControllerParams['controllerConfig'];

  protected constructor(
    protected readonly controllerContext: ControllerParams,
  ) {
    this.controllerConfig = controllerContext.controllerConfig;
    this.currentUser = this.controllerConfig.wixCodeApi.user.currentUser;
    this.errorLogger = ControllerErrorLogger.getInstance(
      this.controllerConfig,
      Environment.VIEWER_WORKER,
    );
    this.appDefId = this.controllerConfig.appParams.appDefinitionId;
  }

  setState(props: Partial<IState>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps(props);
  }

  public getState() {
    return this.state;
  }

  public abstract pageReady($w?: I$WWrapper, wixAPI?: IWixAPI): Promise<any>;

  public updateConfig($w: I$WWrapper, updatedConfig: { [key: string]: any }) {}

  public onBeforeUnLoad() {}

  protected getCurrentUser(): IUser {
    return this.controllerConfig.wixCodeApi.user.currentUser;
  }

  protected isUserLoggedIn(): boolean {
    return this.getCurrentUser().loggedIn;
  }

  protected getSiteToken() {
    return this.controllerConfig.wixCodeApi.site.getAppToken?.(
      this.getAppDefinitionId(),
    );
  }

  protected getAppDefinitionId() {
    return this.appDefId;
  }

  protected getBaseUrls() {
    return this.controllerConfig.appParams.baseUrls;
  }

  protected getLocale() {
    return this.controllerConfig.wixCodeApi.window.locale || 'en';
  }

  protected getSiteLanguage() {
    return this.controllerConfig.wixCodeApi.site.language || 'en';
  }

  protected getApiBaseUrl() {
    return getBaseUrl(this.controllerConfig.wixCodeApi);
  }

  protected getLocation(): ILocation {
    return this.controllerConfig.wixCodeApi.location;
  }

  protected getSiteApis(): ISiteApis {
    return this.controllerConfig.wixCodeApi.site;
  }

  protected getViewMode(): IWixWindowViewMode {
    return this.controllerConfig.wixCodeApi.window.viewMode;
  }

  protected onUserLogin(callback: any) {
    this.controllerConfig.wixCodeApi.user.onLogin((user: any) => {
      this.currentUser = user;
      callback(user);
    });
  }

  protected async promptLogin(options: Partial<any> = {}) {
    try {
      await this.controllerConfig.wixCodeApi.user.promptLogin({
        lang: this.controllerConfig.wixCodeApi.site.language,
        ...options,
      });
    } catch (e) {
      console.log('[BaseWidgetController.promptLogin] Error');
      this.errorLogger.log(e);
    }
  }

  protected isEditorMode() {
    const { viewMode } = this.controllerConfig.wixCodeApi.window;
    // TODO: now Preview is OOI. WILL BREAK
    return ['Editor', 'Preview'].includes(viewMode);
  }

  getInitialProps(): Promise<Partial<IState>> {
    // Props for SSR
    return Promise.resolve({});
  }

  getMemoryStorage(): IWebStorage {
    if (!this.memoryStorage) {
      this.memoryStorage =
        this.controllerConfig.platformAPIs.storage.memory || new BasicStorage();
    }
    return this.memoryStorage;
  }

  getFedopsLogger(): IFedOpsLogger {
    if (!this.fedopsLogger) {
      this.setFedopsLogger();
    }
    return this.fedopsLogger;
  }

  private setFedopsLogger() {
    try {
      this.fedopsLogger = FedopsLogger.fromControllerConfig(
        this.controllerConfig,
      );
    } catch (e) {
      this.errorLogger.log(e);
      console.log('Error in BaseWidgetController.getFedopsLogger');
    }
  }

  private widgetId() {
    return this.controllerConfig.type;
  }

  isSSR() {
    return this.controllerContext.flowAPI.environment.isSSR;
  }

  getMetaSiteId(): string {
    return this.controllerConfig.platformAPIs.bi?.metaSiteId || '';
  }

  getDashboardLink(): string {
    const metaSiteId = this.getMetaSiteId();

    if (metaSiteId === '') {
      return '';
    }

    return `//manage.wix.com/dashboard/${metaSiteId}/social-groups-dashboard/`;
  }

  getSectionUrl(
    sectionId: string,
  ): Promise<{ url?: string; relativeUrl?: string; error?: any }> {
    return this.controllerConfig.wixCodeApi.site.getSectionUrl({
      sectionId,
      appDefinitionId: this.appDefId,
    });
  }

  navigateToSection(sectionInfo?: INavigateToSectionOptions) {
    return this.controllerConfig.wixCodeApi.location.navigateToSection(
      sectionInfo,
    );
  }

  navigateTo(pageInfo?: INavigateToPageOptions) {
    return this.controllerConfig.wixCodeApi.location.navigateTo?.(pageInfo);
  }

  getSiteStructure(options?: {
    includePageId: boolean;
  }): Promise<SiteStructure> {
    return Promise.resolve(
      this.controllerConfig.wixCodeApi.site.getSiteStructure(options),
    );
  }

  getPages(): Promise<StructurePage[]> {
    return this.getSiteStructure({
      includePageId: true,
    }).then((structure) => structure.pages);
  }

  getGroupPages(withHomepage?: boolean): Promise<AppStructurePage[]> {
    return this.getPages().then((pages) => {
      return pages.filter((page: AppStructurePage) => {
        return (
          (page.applicationId && page.applicationId === GROUPS_APP_ID) ||
          (withHomepage && page.isHomePage)
        );
      });
    });
  }

  experimentEnabled(key: string) {
    return this.controllerContext.flowAPI.experiments.enabled(key);
  }

  isInSEO() {
    return this.controllerContext.controllerConfig.wixCodeApi.seo.isInSEO();
  }
}
